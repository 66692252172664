import React, { useState, useRef } from "react";
import { SQuestions } from "./squestions";
import questionsData from "../data/questions.json";
import {
  FaArrowLeft,
  FaCheck,
  FaTimes,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";

import "./survey.css";

export const Survey = () => {
  const url = "https://api.letbabytalk.com/get_survey_result";
  //   const url = "http://127.0.0.1:8000/get_survey_result";
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [answers, setAnswers] = useState([]);
  const [userName, setUserName] = useState("");
  const [expandedQuestions, setExpandedQuestions] = useState({});
  const [theResults, setTheResults] = useState([]);
  const [theCorrect, setTheCorrect] = useState(0);
  const audioRefs = useRef([]);

  const [selectedAnswer, setSelectedAnswer] = useState("");

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
    // console.log(`Selected answer: ${event.target.value}`);
  };

  const toggleExpand = (index) => {
    setExpandedQuestions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const onAudioPlay = (key) => {
    Object.entries(audioRefs.current).forEach(([refKey, audio]) => {
      if (audio) {
      }
      if (refKey !== key && audio && !audio.paused) {
        audio.pause();
        audio.currentTime = 0; // Reset the time
      }
    });
  };

  const handleRestart = () => {
    setCurrentQuestionIndex(-1);
    setAnswers([]);
    setExpandedQuestions({});
    setUserName("");
    setSelectedAnswer("");
  };

  //   const calculateResults = () => {
  //     return answers.map((answer, index) => {
  //       const correctAnswer = questionsData[index].trueAnswer;
  //       const isCorrect = answer.answer == correctAnswer;
  //       return { ...answer, isCorrect };
  //     });
  //   };

  //     const finalResults = calculateResults();
  //     const totalCorrect = finalResults.filter(
  //       (result) => result.isCorrect
  //     ).length;
  //     const accuracy = ((totalCorrect / questionsData.length) * 100).toFixed(2);

  //     const payload = {
  //       userName,
  //       totalCorrect,
  //       accuracy,
  //       results: finalResults,
  //     };

  //     try {
  //       const response = await fetch(url, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(payload),
  //       });

  //       if (response.ok) {
  //         console.log("Results successfully sent to the backend!");
  //       } else {
  //         console.error("Failed to send results:", response.statusText);
  //       }
  //     } catch (err) {
  //       console.error("Error sending results to backend:", err);
  //     }
  //   };

  //   const handleAnswer = (selectedOption) => {
  //     setAnswers((prevAnswers) => {
  //       const updatedAnswers = [...prevAnswers];
  //       updatedAnswers[currentQuestionIndex] = {
  //         question: currentQuestionIndex + 1,
  //         answer: selectedOption,
  //       };
  //       return updatedAnswers;
  //     });

  //     if (currentQuestionIndex < questionsData.length - 1) {
  //       setCurrentQuestionIndex(currentQuestionIndex + 1);
  //     } else {
  //       console.log("Survey completed!", answers);
  //       setCurrentQuestionIndex(currentQuestionIndex + 1);
  //       sendResultsToBackend();
  //     }
  //   };

  const handleAnswer = (selectedOption) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentQuestionIndex] = {
        question: currentQuestionIndex + 1,
        userAnswer: selectedOption,
      };

      if (currentQuestionIndex === questionsData.length - 1) {
        // If it's the last question, ensure to send the updated state
        const finalResults = updatedAnswers.map((answer, index) => {
          const correctAnswer = questionsData[index].trueAnswer;

          console.log(
            correctAnswer,
            answer.userAnswer,
            answer.userAnswer == correctAnswer
          );
          const isCorrect = answer.userAnswer == correctAnswer;
          return { ...answer, isCorrect };
        });
        setTheResults(finalResults);

        const totalCorrect = finalResults.filter(
          (result) => result.isCorrect
        ).length;
        setTheCorrect(totalCorrect);
        const accuracy = ((totalCorrect / questionsData.length) * 100).toFixed(
          2
        );

        const payload = {
          userName,
          selectedAnswer,
          totalCorrect,
          accuracy,
          results: finalResults,
        };

        sendResultsToBackend(payload);
      }

      return updatedAnswers;
    });

    if (currentQuestionIndex < questionsData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      console.log("Survey completed!");
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const sendResultsToBackend = async (payload) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log("Results successfully sent to the backend!");
      } else {
        console.error("Failed to send results:", response.statusText);
      }
    } catch (err) {
      console.error("Error sending results to backend:", err);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleStartSurvey = () => {
    if (userName.trim() === "") {
      alert("Please enter your name to start the survey.");
    } else if (selectedAnswer === "") {
      alert("Please select an answer to start the survey.");
    } else {
      setCurrentQuestionIndex(0);
    }
  };

  const question = questionsData[currentQuestionIndex];

  //   const finalResults = calculateResults();
  //   const totalCorrect = finalResults.filter((result) => result.isCorrect).length;

  return (
    <div className="survey-container">
      <h1 className="survey-title">Can You Identify the Cries?</h1>
      {currentQuestionIndex == -1 && (
        <>
          <h3 className="survey-subtitle">
            Can you uncover the secrets behind a cry?
          </h3>
          <h3 className="survey-subtitle">
            {" "}
            Test your ability to identify the reasons behind different cries.
            Start now!
          </h3>
        </>
      )}

      {currentQuestionIndex === -1 ? (
        <div className="survey-start">
          <h4>Enter your name to start the survey:</h4>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="survey-input"
          />
          <h4>Have you ever had experience taking care of a baby?</h4>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <select
              value={selectedAnswer}
              onChange={handleAnswerChange}
              style={{
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                cursor: "pointer",
                backgroundColor: "#f9f9f9",
              }}
            >
              <option value="" disabled>
                Select an answer
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <button onClick={handleStartSurvey} className="survey-start-button">
            Start
          </button>
        </div>
      ) : currentQuestionIndex < questionsData.length ? (
        <div>
          <div className="survey-navigation">
            {currentQuestionIndex > 0 ? (
              <button
                onClick={handlePrevious}
                className="survey-previous-button"
              >
                <FaArrowLeft className="survey-icon" /> Previous
              </button>
            ) : (
              <div className="survey-placeholder"></div>
            )}
            <span className="survey-question-number">
              Question {currentQuestionIndex + 1} of {questionsData.length}
            </span>
          </div>
          <SQuestions
            classAAudios={question.classAAudios}
            classBAudios={question.classBAudios}
            testAudio={question.testAudio}
            options={question.options}
            onAnswer={handleAnswer}
            currentQuestionIndex={currentQuestionIndex}
            audioRefs={audioRefs}
            onAudioPlay={onAudioPlay}
          />
        </div>
      ) : (
        <div className="survey-results">
          <h2>Survey Results</h2>
          <h5>Thank you, {userName}, for completing the survey!</h5>
          <h4>
            You got {theCorrect} out of {questionsData.length} correct.
          </h4>
          <ul>
            {theResults.map((result, index) => (
              <li key={index} className="result-item">
                <div
                  className="result-header"
                  onClick={() => toggleExpand(index)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "10px",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <span>
                    Question {result.question}: {result.userAnswer}{" "}
                    {result.isCorrect ? (
                      <FaCheck
                        className="correct-icon"
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FaTimes
                        className="wrong-icon"
                        style={{ color: "red" }}
                      />
                    )}
                  </span>
                  {expandedQuestions[index] ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </div>
                {expandedQuestions[index] && (
                  <div
                    className={`result-details ${
                      expandedQuestions[index] ? "expanded" : ""
                    }`}
                    style={{ padding: "10px" }}
                  >
                    <p>{questionsData[index].options[0]}</p>
                    {questionsData[index].classAAudios.map((audio, idx) => (
                      <audio
                        key={`${currentQuestionIndex}-classA-${idx}`}
                        controls
                        controlsList="nodownload"
                        ref={(el) =>
                          (audioRefs.current[`${index}-classA-${idx}`] = el)
                        }
                        onPlay={() => onAudioPlay(`${index}-classA-${idx}`)}
                      >
                        <source src={audio} type="audio/wav" />
                        Your browser does not support the audio element.
                      </audio>
                    ))}
                    <p>{questionsData[index].options[1]}</p>
                    {questionsData[index].classBAudios.map((audio, idx) => (
                      <audio
                        key={`${currentQuestionIndex}-classB-${idx}`}
                        controls
                        controlsList="nodownload"
                        ref={(el) =>
                          (audioRefs.current[`${index}-classB-${idx}`] = el)
                        }
                        onPlay={() => onAudioPlay(`${index}-classB-${idx}`)}
                      >
                        <source src={audio} type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    ))}
                    <p>Test Audio:</p>
                    <audio
                      key={`${currentQuestionIndex}-test`}
                      controls
                      controlsList="nodownload"
                      ref={(el) => (audioRefs.current[`${index}-test`] = el)}
                      onPlay={() => onAudioPlay(`${index}-test`)}
                    >
                      <source
                        src={questionsData[index].testAudio}
                        type="audio/mp3"
                      />
                      Your browser does not support the audio element.
                    </audio>
                    <p>Correct Answer: {questionsData[index].trueAnswer}</p>
                  </div>
                )}
              </li>
            ))}
          </ul>

          <button className="survey-restart-button" onClick={handleRestart}>
            Restart
          </button>
        </div>
      )}
    </div>
  );
};
