import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
            Collaboration
          </h2>
          <p
            style={{
              fontStyle: "italic",
              textAlign: "center",
              margin: "0 auto",
              maxWidth: "600px",
            }}
          >
            Partnerships with leading global universities and research
            institutions.
          </p>
        </div>
        <div className="row">
          <div className="collaboration-grid">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="collaboration-item">
                    <Image smallImage={d.smallImage} />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>

      {/* Styles for responsive layout */}
      <style jsx>{`
        .container {
          max-width: 1200px; /* Limit the maximum width */
          margin: 0 auto; /* Center the container */
          padding: 0 15px; /* Add padding to prevent overflow */
          box-sizing: border-box; /* Include padding and borders in width/height calculations */
        }

        .collaboration-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Default grid layout */
          gap: 12px; /* Space between grid items */
          margin-top: 20px;
          width: 100%; /* Ensure the grid does not exceed container width */
        }

        .collaboration-item {
          padding: 4px; /* Add padding around each item */
        }

        .collaboration-item img {
          height: 70px; /* Consistent height for images */
          width: 100%; /* Full width within the grid item */
          object-fit: contain; /* Maintain aspect ratio */
        }

        /* Medium screens (tablets) */
        @media (max-width: 768px) {
          .collaboration-grid {
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Adjust min width for medium screens */
            gap: 10px; /* Reduce gap slightly */
          }
        }

        /* Small screens (mobile) */
        @media (max-width: 480px) {
          .collaboration-grid {
            grid-template-columns: 1fr; /* Single column layout for small screens */
            gap: 8px; /* Reduce gap further */
          }
        }
      `}</style>
    </div>
  );
};

// import { Image } from "./image";
// import React from "react";

// export const Gallery = (props) => {
//   return (
//     <div id="portfolio" className="text-center">
//       <div className="container">
//         <div className="section-title">
//           <h2>Gallery</h2>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
//             dapibus leonec.
//           </p>
//         </div>
//         <div className="row">
//           <div className="portfolio-items">
//             {props.data
//               ? props.data.map((d, i) => (
//                   <div
//                     key={`${d.title}-${i}`}
//                     className="col-sm-6 col-md-4 col-lg-4"
//                   >
//                     <Image
//                       title={d.title}
//                       largeImage={d.largeImage}
//                       smallImage={d.smallImage}
//                     />
//                   </div>
//                 ))
//               : "Loading..."}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

