import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import ProjectData from "../data/letbabytalkData.json";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  EffectCube,
  EffectFlip,
} from "swiper/modules";
import "swiper/css";
// import "swiper/css/navigation";
import "swiper/css/pagination";

export const Project = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [hoveredLeft, setHoveredLeft] = useState(false);

  const handleMouseEnterLeft = () => {
    setHoveredLeft(true); // Set hovered state to true when the mouse enters
  };

  const handleMouseLeaveLeft = () => {
    setHoveredLeft(false); // Set hovered state to false when the mouse leaves
  };

  const [hoveredRight, setHoveredRight] = useState(false);

  const handleMouseEnterRight = () => {
    setHoveredRight(true); // Set hovered state to true when the mouse enters
  };

  const handleMouseLeaveRight = () => {
    setHoveredRight(false); // Set hovered state to false when the mouse leaves
  };

  useEffect(() => {
    if (props.from === "homepage") {
      window.scrollTo(0, 0);
    }
  }, []);

  let data = props.data;

  // useEffect(() => {

  // }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [fade, setFade] = useState(true); // 用于控制淡入淡出
  const [animating, setAnimating] = useState(false);
  const [nextIndex, setNextIndex] = useState(
    (currentImageIndex + 1) % data?.prototype_images.length
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearFormState = () =>
    setFormState({ name: "", email: "", message: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formState;

    const templateParams = {
      name,
      email,
      message,
    };

    emailjs
      .send(
        "service_qmbn0wi",
        "template_zg8lttf",
        templateParams,
        "fIkHZBH5assDXC6wW"
      )
      .then(
        (result) => {
          alert("Thank you. We have received your message.");
          clearFormState();
        },
        (error) => {
          alert(
            "Sorry. Message failed. Please send email to help@cradle-ai.com"
          );
        }
      );
  };

  const nextImage = () => {
    setFade(false); // 开始淡出
    setTimeout(() => {
      setCurrentImageIndex(
        (prev) => (prev + 1) % (data?.prototype_images?.length || 1)
      );
      setFade(true); // 开始淡入
    }, 1000);
  };

  const prevImage = () => {
    setFade(false); // 开始淡出
    setTimeout(() => {
      setCurrentImageIndex(
        (prev) =>
          (prev - 1 + (data?.prototype_images?.length || 1)) %
          (data.prototype_images?.length || 1)
      );
      setFade(true); // 开始淡入
    }, 1000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // 开始淡出
      setTimeout(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % data?.prototype_images.length
        );
        setFade(true); // 开始淡入
      }, 1000); // 与 CSS transition 时间一致
    }, 5000); // 自动切换间隔时间

    return () => clearInterval(interval); // 清除计时器
  }, [data.prototype_images.length]);

  return (
    <div style={{ fontFamily: "'Open Sans', sans-serif", color: "#333" }}>
      {/* Navigation Bar */}
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top">
              CradleAI
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="/" className="page-scroll">
                  Home
                </a>
              </li>
              <li>
                {/* <a href="#features" className="page-scroll">
                Features
              </a>
            </li>
            <li> */}
                <a href="#prototype" className="page-scroll">
                  Prototype
                </a>
              </li>
              <li>
                <a href="#features" className="page-scroll">
                  Features
                </a>
              </li>
              {/* <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li> */}
              <li>
                <a href="#cases" className="page-scroll">
                  Use Cases
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Header Section */}
      <header id="header">
        <div className="letbabytalk">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Prototype Section */}
      <section
        id="prototype"
        style={{ padding: "100px 20px", backgroundColor: "#f9f9f9" }}
      >
        {/* Title */}
        <div className="section-title">
          <h2 style={{ textAlign: "center" }}>Prototype</h2>
          <p style={{ textAlign: "center", fontStyle: "italic" }}>
            Introducing the app's core features and user guidance for an
            intuitive experience.
          </p>
        </div>

        {/* Container for Swiper and Description */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "fit-content", // You can adjust the height of the entire section
            alignItems: "stretch", // This will make both sections stretch to the same height
            gap: "20px", // Space between the Swiper and Description
            width: "100%",
            maxWidth: "1200px",
            margin: "0 auto",
            // padding: "20px",
          }}
        >
          {/* Swiper Wrapper */}
          <div
            style={{
              width: "50%", // Adjust to your desired width
              maxWidth: "300px",
              // padding: "10px",
              height: "650px",
              // maxHeight: "600px",
            }}
          >
            <Swiper
              modules={[Navigation, A11y, Autoplay]}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              pagination={{ clickable: true }}
              onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)} // Update index on slide change
              autoplay={{
                delay: 3000, // 3 seconds
                disableOnInteraction: true, // Keep autoplay running after user interaction
              }}
              loop={true}
              spaceBetween={30}
              slidesPerView={1}
              style={{ padding: "20px 0" }}
            >
              {data.prototype_images.map((image, index) => (
                <SwiperSlide key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      overflow: "hidden",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                      backgroundColor: "#fff",
                    }}
                  >
                    <img
                      src={image}
                      alt={`Prototype ${index + 1}`}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div class="swiper-button-prev">
                    <button
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        fontSize: "1.5rem",
                        backgroundColor: hoveredLeft
                          ? "rgba(0, 0, 0, 0.6)"
                          : "rgba(0, 0, 0, 0.2)", // Change opacity on hover

                        border: "none",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                        color: "rgb(255, 255, 255)",
                        transition: "background-color 0.3s",
                        zIndex: 1,
                      }}
                      onMouseEnter={handleMouseEnterLeft} // Trigger hover effect
                      onMouseLeave={handleMouseLeaveLeft} // Revert on hover out
                    >
                      &lt;
                    </button>
                  </div>
                  <div class="swiper-button-next">
                    <button
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        fontSize: "1.5rem",
                        backgroundColor: hoveredRight
                          ? "rgba(0, 0, 0, 0.6)"
                          : "rgba(0, 0, 0, 0.2)", // Change opacity on hover
                        border: "none",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                        color: "rgb(255, 255, 255)",
                        transition: "background-color 0.3s",
                        zIndex: 1,
                      }}
                      onMouseEnter={handleMouseEnterRight} // Trigger hover effect
                      onMouseLeave={handleMouseLeaveRight} // Revert on hover out
                    >
                      &gt;
                    </button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Description Section */}

          <div
            style={{
              width: "50%", // Adjust to your desired width
              height: "650px",
              margin: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start", // Align items from the top
              alignItems: "stretch",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            {/* Descriptions */}
            <div
              style={{
                flexGrow: 1, // Allow descriptions to take up remaining space
              }}
            >
              <p
                style={{
                  fontSize: "2.5rem",
                  color: "#333",
                  marginBottom: "10px",
                  lineHeight: "1.8",
                  fontWeight: "600",
                }}
              >
                {data.prototype_descriptions[currentIndex]}
              </p>
              <div
                style={{
                  fontSize: "1.8rem",
                  color: "#666",
                  lineHeight: "1.7",
                }}
              >
                {data.prototype_additional_descriptions[currentIndex] &&
                  Array.isArray(
                    data.prototype_additional_descriptions[currentIndex]
                  ) &&
                  data.prototype_additional_descriptions[currentIndex].map(
                    (line, i) => (
                      <p key={i} style={{ marginBottom: "10px" }}>
                        {line}
                      </p>
                    )
                  )}
              </div>
            </div>

            {/* Download Links */}
            <div
              style={{
                textAlign: "center",
                marginTop: "auto", // Pushes this section to the bottom
                marginBottom: "40px",
              }}
            >
              <h3
                style={{
                  fontSize: "2.5rem",
                  color: "#333",
                  marginBottom: "25px",
                }}
              >
                Download the App
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "40px",
                }}
              >
                <a
                  href={data.google_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "inline-block",
                    width: "150px",
                  }}
                >
                  <img
                    src="../img/google_play.png"
                    alt="Download on Google Play"
                    style={{ width: "100%" }}
                  />
                </a>
                <a
                  href={data.apple_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "inline-block",
                    width: "150px",
                  }}
                >
                  <img
                    src="../img/apple_store.png"
                    alt="Download on the App Store"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section
        id="features"
        style={{ padding: "100px 20px", backgroundColor: "#ffffff" }}
      >
        <div
          className="container"
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <div className="section-title">
            <h2 style={{ textAlign: "center" }}>Features</h2>
            <p style={{ textAlign: "center", fontStyle: "italic" }}>
              Highlighting the key functionalities that make the app valuable
              and user-friendly.
            </p>
          </div>

          <div
            className="features-grid"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            {data.features.map((feature, index) => (
              <div
                key={index}
                className="feature-card"
                style={{
                  width: "250px",
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "10px",
                  textAlign: "center",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease",
                }}
              >
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "0 auto 20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    // background: "linear-gradient(to right, #6372ff, #5ca9fb)",
                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <i
                    className={feature.icon}
                    style={{
                      fontSize: "38px",
                      color: "#fff",
                      marginTop: "20px",
                    }}
                  ></i>
                </div>
                <h3
                  style={{
                    fontSize: "2rem",
                    marginBottom: "10px",
                    color: "#333",
                  }}
                >
                  {feature.title}
                </h3>
                <p style={{ fontSize: "1.5rem", color: "#555" }}>
                  {feature.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section
        id="cases"
        style={{ padding: "100px 20px", backgroundColor: "#f6f6f6" }}
      >
        <div
          className="container"
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <div className="section-title">
            <h2 style={{ textAlign: "center" }}>Use Cases</h2>
            <p style={{ textAlign: "center", fontStyle: "italic" }}>
              Showcasing practical scenarios where the app helps solve real-life
              parenting challenges.
            </p>
          </div>
        </div>

        {/* Use Cases Grid */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {props.data.use_cases.map((useCase, index) => (
            <div
              key={index}
              style={{
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "translateY(-10px)";
                e.currentTarget.style.boxShadow =
                  "0px 8px 12px rgba(0, 0, 0, 0.2)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow =
                  "0px 4px 6px rgba(0, 0, 0, 0.1)";
              }}
            >
              {/* Use Case Icon */}
              <img
                src={useCase.icon}
                alt={useCase.title}
                style={{
                  width: "200px",
                  height: "200px",
                  marginBottom: "20px",
                  objectFit: "cover", // 确保图片填充整个正方形区域
                  borderRadius: "8px", // 可选，添加圆角效果
                }}
              />

              {/* Use Case Title */}
              <h3
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#333",
                }}
              >
                {useCase.title}
              </h3>

              {/* Use Case Description */}
              <p
                style={{ fontSize: "1.2rem", color: "#666", lineHeight: "1.6" }}
              >
                {useCase.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Contact Section */}
      <section
        id="contact"
        style={{
          padding: "100px 20px",
          backgroundColor: "#f9f9f9",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          className="container"
          style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 15px" }}
        >
          <div className="row">
            <div className="col-md-8">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>{" "}
                  {props.data.contact ? props.data.contact.address : "loading"}
                </p>
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {props.data.contact ? props.data.contact.phone : "loading"}
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data.contact ? props.data.contact.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.facebook : "/"}>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.twitter : "/"}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.linkedin : "/"}>
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.instagram : "/"}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const formatText = (text) => {
  const boldPhrases = [
    "Better understand your baby's needs",
    "Explain the reason why baby cries",
    "Provide personalized advice",
    "Add Baby's Details",
    "Customize the Profile Picture",
    "Personalization Benefits",
    "Tap to Start Recording",
    "Real-Time Feedback",
    "Interactive and Simple",
    "Navigate the Tabs",
    "Review Past Insights",
    "Customize Settings",
    "Analysis Summary",
    "Explanation Section",
    "Actionable Recommendations",
    "Daily Summary",
    "Detailed Record List",
    "Playback Feature",
  ];

  let formattedText = text;
  boldPhrases.forEach((phrase) => {
    const regex = new RegExp(`(${phrase})`, "g");
    formattedText = formattedText.replace(regex, `<strong>${phrase}</strong>`);
  });

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: formattedText,
      }}
    />
  );
};
